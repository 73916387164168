
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import AreaSelect from '@/components/area-select.vue'
import OrderLogistics from '@/components/order/business-order-logistics.vue'
import {
    apiOrderDetail,
    apiOrderChangeExpressPrice,
    apiOrderRemarks,
    apiOrderConfirm,
    apiOrderCancel,
    apiOrderChangeGoodsPrice,
    apiOrderAddressEdit,
    apiOrderChangeDelivery,
    apiOrderDeliveryInfo,
    apiOrderConfirmpay
} from '@/api/goods_order/business_order'
import { apiSelffetchVerification } from '@/api/application/selffetch'
@Component({
    components: {
        LsDialog,
        AreaSelect,
        OrderLogistics
    }
})
export default class OrderDetail extends Vue {
    $refs!: { verifyDialogRef: any }
    // S Data

    // 订单详情ID
    id: any = 0

    // 订单数据
    orderData: any = {
        admin_order_btn: {
            remark_btn: 1,
            cancel_btn: 0,
            confirm_btn: 0,
            logistics_btn: 0,
            refund_btn: 0,
            address_btn: 1,
            price_btn: 1
        }
    }

    address: any = {
        province_id: '', //必填	int	所在地区:省id
        city_id: '', //必填	int	所在地区:市id
        district_id: '', //必填	int	所在地区:区id
        address: '' //必填	varchar	详细地址
    }

    // 商家备注
    remarks: String = ''
    verifyTips = ''

    // 运费更改
    express_price: String = ''

    // 商品价格
    goods_price: String = ''

    // 物流公司
    express_id: String = ''

    // 快递单号
    invoice_no: String = ''

    // 物流公司数组
    expressList: Array<any> = []
    // E Data

    // S Methods
    // 获取订单详情
    getOrderDetail() {
        apiOrderDetail({ id: this.id }).then(res => {
            this.orderData = res

            if (res.delivery_type == 1 && res.order_status > 1 && res.send_type == 1) {
                this.getOrderDeliveryInfo()
            }
        })
    }

    // 取消订单
    orderCancel() {
        apiOrderCancel({ id: this.id }).then(res => {
            this.getOrderDetail()
        })
    }

    // 提货核销
    selffetch(isConfirm = 0) {
        apiSelffetchVerification({ id: this.id, confirm: isConfirm }).then(res => {
            if (res.code === 10) {
                this.verifyTips = res.msg
                this.$nextTick(() => {
                    this.$refs.verifyDialogRef?.open()
                })
                return
            }
            this.getOrderDetail()
        })
    }

    // 订单地址修改
    orderAddressSet() {
        apiOrderAddressEdit({
            id: this.id,
            ...this.address
        }).then(res => {
            this.getOrderDetail()
        })
    }

    // 确认收货
    orderConfirm() {
        apiOrderConfirm({ id: this.id }).then(res => {
            this.getOrderDetail()
        })
    }
    orderConfirmpay() {
        apiOrderConfirmpay({ id: this.id }).then(res => {
            this.getOrderDetail()
        })
    }

    // 商家备注
    postOrderRemarks() {
        apiOrderRemarks({
            id: [this.id],
            order_remarks: this.remarks
        }).then(res => {
            this.getOrderDetail()
        })
    }

    // 修改商品价格
    orderChangeGoodsPrice(id: Number) {
        if (this.goods_price == '') {
            return this.$message.error('请输入价格')
        }
        apiOrderChangeGoodsPrice({
            order_goods_id: id,
            change_price: this.goods_price
        }).then(res => {
            this.getOrderDetail()
            this.goods_price = ''
        })
    }

    // 修改运费
    orderChangeExpress(id: number) {
        if (this.express_price == '') {
            return this.$message.error('请输入运费！')
        }
        apiOrderChangeExpressPrice({
            order_goods_id: id,
            express_price: this.express_price
        }).then(res => {
            this.getOrderDetail()
        })
    }

    // 修改物流公司
    orderChangeLogistics() {
        if (this.express_id == '') {
            return this.$message.error('请输入物流公司名称！')
        }
        apiOrderChangeDelivery({
            id: this.id,
            express_id: this.express_id
        }).then(res => {
            this.getOrderDetail()
            this.express_id = ''
        })
    }

    // 修改快递单号
    orderChangeCourierNumber() {
        if (this.invoice_no == '') {
            return this.$message.error('请输入快递单号！')
        }
        apiOrderChangeDelivery({
            id: this.id,
            invoice_no: this.invoice_no
        }).then(res => {
            this.getOrderDetail()
            this.invoice_no = ''
        })
    }

    // 商品信息的底部结算信息
    getSummaries(param: any) {
        const { columns, data } = param
        const sums: any = []
        columns.forEach((column: any, index: any) => {
            if (index === 0) {
                sums[0] = '总价'
                return
            }
            const values = data.map((item: any) => Number(item[column.property]))
            if (!values.every((value: any) => isNaN(value))) {
                if (index == 1) {
                    return
                }
                sums[index] = values.reduce((prev: any, curr: any) => {
                    const value = Number(curr)
                    if (!isNaN(value)) {
                        return prev + curr
                    }
                    return prev
                }, 0)
                if (index !== 3) {
                    if (typeof sums[index] == 'number') {
                        sums[index] = sums[index].toFixed(2)
                    }
                    sums[index] = '¥' + sums[index]
                }
                if (index == 5) {
                    if (typeof sums[index] == 'number') {
                        sums[index] = sums[index].toFixed(2)
                    }
                    sums[index] = '-' + sums[index]
                }
                if (index == 6) {
                    sums[index] = '-¥' + (this.orderData.change_price || '0.00')
                }
            }
        })
        return sums
    }

    toUserDetail() {
        this.$router.push({
            path: '/user/user_details',
            query: {
                id: this.orderData.user_id
            }
        })
    }

    // 获取订单物流信息
    getOrderDeliveryInfo() {
        apiOrderDeliveryInfo({ id: this.id }).then(res => {
            this.expressList = res.express
        })
    }

    getDiscountPrice(item: any) {
        const { integral_discount, member_discount, coupon_discount } = item
        return (Number(integral_discount) + Number(member_discount) + Number(coupon_discount)).toFixed(2)
    }

    // E Methods

    created() {
        this.id = this.$route.query.id
        this.id && this.getOrderDetail()
    }
    /** S computed **/
    // 总的商品总额
    get goods_total_price() {
        let total = 0
        if (this.orderData.order_type == 0 || this.orderData.order_type == 4) {
            this.orderData.order_goods.forEach((i: any) => {
                return (total += Number(i.total_amount))
            })
        } else if (
            this.orderData.order_type == 1 ||
            this.orderData.order_type == 2 ||
            this.orderData.order_type == 3 ||
            this.orderData.order_type == 5
        ) {
            this.orderData.order_goods.forEach((i: any) => {
                return (total += Number(i.total_price))
            })
        }
        return total.toFixed(2)
    }
    /** E computed **/
}
